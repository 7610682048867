import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import CommentComponent from 'components/community/Comment';

import { postDetailData } from './TempData';
import KeyboardInput from 'components/community/KeyboardInput';

const PostDetailViewContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
`;

// header
const PostHeaderWapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-top: 47px;
`;

const PostHeaderBack = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    left: 0;
    width: 64px;
`;

const PostHeaderHeart = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    right: 56px;
    width: 64px;
`;

const PostHeaderShare = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    right: 0;
    width: 64px;
`;

// body
const PostTitle = styled.div`
    display: flex;
    padding: 28px 24px 8px 24px;

    // text
    font-family: Pretendard JP;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

const PostUserInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 24px 0px 24px;
    gap: 8px;
`;

const UserImg = styled.img`
    display: flex;
    width: 32px;
    height: 32px;
`;

const UserInfoWapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserNickName = styled.div`
    display: flex;

    // text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #777E88; 
`;

const UserInfo = styled.div`
    display: flex;

    // text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #777E88;
`;

const PostContent = styled.div`
    display: flex;
    padding: 40px 24px 56px 24px;

    // text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #32373E;
`;

const CommentHeaderWrapper = styled.div`
    display: flex;
    padding: 32px 24px 8px 24px;
`;

const CommentCount = styled.div`
    display: flex;
    flex: 1;

    // text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

const CommentSortOptions = styled.div`
    display: flex;
    gap: 8px;
`;

const CommentSortOptionItem = styled.div<{$isSelected: boolean}>`    
    display: flex;

    //text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${props => props.$isSelected ? '#32373E' : '#777E88'};
`;

const CommentWapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 24px 90px 24px;
`;

const ContourLine = styled.div`
    disflay: flex;
    background: #F6F8F9;
    height: 16px;
`;

const PostDetail: React.FC = () => {
    const [sortOption, setSortOption] = useState<'등록순' | '최신순'>('등록순');
    const navigate = useNavigate();
    const { postNumber } = useParams();

    if(!postNumber) return null;

    const btnBackClick = () => {
        navigate(-1);
    };

    const postDatas = postDetailData.find(post => post.postNum === parseInt(postNumber));

    if(!postDatas) return null;

    return (
        <PostDetailViewContainer>
            {/* header */}
            <PostHeaderWapper>
                <PostHeaderBack onClick={btnBackClick}>
                    <img src='/images/chevron-left.svg' />
                </PostHeaderBack>

                <PostHeaderHeart onClick={btnBackClick}>
                    <img src='/images/community/heart.svg' />
                </PostHeaderHeart>

                <PostHeaderShare onClick={btnBackClick}>
                    <img src='/images/community/share.svg' />
                </PostHeaderShare>
            </PostHeaderWapper>

            {/* content */}
            <PostTitle>{postDatas.title}</PostTitle>

            <PostUserInfo>
                <UserImg src='/images/community/profile.png'></UserImg>

                <UserInfoWapper>
                    <UserNickName>{postDatas.nickname}</UserNickName>
                    <UserInfo>{postDatas.createdAt} ㅣ {postDatas.viewCount}</UserInfo>
                </UserInfoWapper>
            </PostUserInfo>

            <PostContent>
                {postDatas.content}
            </PostContent>

            <ContourLine />

            {/* comment */}
            <CommentHeaderWrapper>
                <CommentCount>{postDatas.commentCount}개의 댓글</CommentCount>

                <CommentSortOptions>
                    <CommentSortOptionItem 
                        $isSelected={sortOption === '등록순'} 
                        onClick={() => setSortOption('등록순')}>등록순
                    </CommentSortOptionItem>
                    <CommentSortOptionItem 
                        $isSelected={sortOption === '최신순'} 
                        onClick={() => setSortOption('최신순')}>최신순
                    </CommentSortOptionItem>
                </CommentSortOptions>
            </CommentHeaderWrapper>

            <CommentWapper>
                {postDatas.comments.map((postData, idx) => (
                    <CommentComponent key={idx} data={postData}></CommentComponent>
                ))}
            </CommentWapper>

            <KeyboardInput />
        </PostDetailViewContainer>
    );
};

export default PostDetail;
