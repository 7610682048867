import React, { useState } from 'react';

import styled from 'styled-components';

import BogleText from 'components/common/BogleText';
import BogleToggle from 'components/common/BogleToogle';
import BogleButton from 'components/common/BogleButton';
import BottomSheet from './BottomSheet';
import { SORT_CRITERIA } from 'pages/review/Review';

const BottomSheetTitleWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 1.5rem 0 2rem;
    align-items: center;
    justify-content: center;
`;

const BottomSheetListWrapper = styled.div`
    display: flex;
    padding: 0.938rem 1.25rem;
    gap: 1rem;
    align-items: center;
`;

interface SortBottomSheetProps {
    isBottomSheetOpen: boolean
    setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;

    sort: string;
    setSort: React.Dispatch<React.SetStateAction<string>>;
}

const SortBottomSheet: React.FC<SortBottomSheetProps> = ({ isBottomSheetOpen, setIsBottomSheetOpen, sort, setSort,  }) => {
    const [bottomSort, setBottomSort] = useState(sort);

    const onClickedSetSort = () => {
        setIsBottomSheetOpen(false);
        setSort(bottomSort);
    };

    return (
        <BottomSheet isBottomSheetOpen={isBottomSheetOpen} setIsBottomSheetOpen={setIsBottomSheetOpen}>
            <BottomSheetTitleWrapper>
                <BogleText fontSize='1.188rem' color='#2A2C32'>정렬 기준</BogleText>
            </BottomSheetTitleWrapper>

            <BottomSheetListWrapper>
                <BogleToggle 
                    isOn={bottomSort === SORT_CRITERIA.LATEST}
                    onToggle={() => { setBottomSort(SORT_CRITERIA.LATEST); }} 
                />
                <BogleText color={bottomSort === SORT_CRITERIA.LATEST ? '#32373E' : '#777E88'}>최신 등록 순</BogleText>
            </BottomSheetListWrapper>

            <BottomSheetListWrapper>
                <BogleToggle 
                    isOn={bottomSort === SORT_CRITERIA.RATING}
                    onToggle={() => { setBottomSort(SORT_CRITERIA.RATING); }} 
                />
                <BogleText color={bottomSort === SORT_CRITERIA.RATING ? '#32373E' : '#777E88'}>별점 높은 순</BogleText>
            </BottomSheetListWrapper>

            <BottomSheetListWrapper>
                <BogleToggle 
                    isOn={bottomSort === SORT_CRITERIA.ALPHABETICAL}
                    onToggle={() => { setBottomSort(SORT_CRITERIA.ALPHABETICAL); }} 
                />
                <BogleText color={bottomSort === SORT_CRITERIA.ALPHABETICAL ? '#32373E' : '#777E88'}>가나다 순</BogleText>
            </BottomSheetListWrapper>

            <BogleButton 
                width='90%'
                height='3.5rem' 
                $background='#A57BDB' 
                radius='1rem' 
                style={{ position: 'relative', left: '5%', justifyContent: 'center', margin: '2rem 0rem 1.563rem'}}
                onClick={onClickedSetSort}>
                <BogleText fontSize='1.063rem'>확인</BogleText>
            </BogleButton>
        </BottomSheet>
    );
};

export default SortBottomSheet;
