import { Comment } from 'pages/community/TempData';
import React from 'react';

import styled from 'styled-components';

const CommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CommentInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 0px 6px 0px;
    gap: 10px;
`;

const ProfileWrapper = styled.div`
    width: 32px;
    height: 32px;
    background: #F6EDE9;
    border-radius: 999px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProfileImage = styled.div`
    width: 32px;
    height: 32px;
    background: #F6EDE9;
    border-radius: 999px;
`;

const EmojiIcon = styled.div`
    position: absolute;
    width: 20px;
    height: 20px;
    left: 6px;
    top: 6px;
    border-radius: 2.5px;
`;

const TextWapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
`;

const NameWapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`;

const UserName = styled.div`
    font-family: 'Pretendard JP';
    font-size: 12px;
    font-weight: 400;
    color: #2A2C32;
`;

const CommentDate = styled.div`
    font-family: 'Pretendard JP';
    font-size: 12px;
    font-weight: 400;
    color: #707684;
`;

const CommentContent = styled.div`
    display: flex;

    // text
    font-family: 'Pretendard JP';
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

const MoreOption = styled.div`
    display: flex;
    padding: 4px 0px;
`;

const CommentReply = styled.div`
    display: flex;
    padding: 0px 0px 16px 42px;

    // text
    font-family: 'Pretendard JP';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #707684;
`;

interface CommentProps {
    data: Comment;
}

const CommentComponent: React.FC<CommentProps> = ({data}) => {

    const moreOptionClick = () => {
        console.log('타코 버튼 클릭');
    };

    return (
        <CommentWrapper>
            <CommentInfoWrapper>
                <ProfileWrapper>
                    <ProfileImage>
                        <EmojiIcon>
                            <img src='/images/community/dog-face.png'/>
                        </EmojiIcon>
                    </ProfileImage>
                </ProfileWrapper>

                <TextWapper>
                    <NameWapper>
                        <UserName>{data.nickname}</UserName>
                        <CommentDate>2 hours ago</CommentDate>
                    </NameWapper>
                    <CommentContent>{data.content}</CommentContent>
                </TextWapper>

                <MoreOption onClick={moreOptionClick}>
                    <img src='/images/community/dot-grid-1x3-vertical.svg'/>
                </MoreOption>
            </CommentInfoWrapper>
            
            <CommentReply>
                답글 달기
            </CommentReply>
        </CommentWrapper>
    );
};

export default CommentComponent;
