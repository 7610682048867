import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';
import BoardGameList_Board from 'components/boardGameList/BoardGameList.Board';
import { communityPosts } from './TempData';

const CommunityBoardContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: calc(100% - 6.375rem);
    position: relative;
    padding-bottom: 6.375rem;
`;

const PostDetailContainer = styled.div`
    padding: 24px;
`;

const FloatingButtonWapper = styled.div`
    position: fixed;
    right: 1.5rem;
    bottom: 25px;
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.25));
`;

const BoardListWapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CommunityBoard: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const title = location.state?.title;

    const btnBackClick = () => {
        navigate(-1);
    };

    const onClickedFloatingButton = () => {
        navigate("/community/post");
    };

    const onClickedBoardItem = (postNumber: number) => {
        navigate(`./${postNumber}`);
    };

    return (
        <CommunityBoardContainer>
            <AppBar1 backAction={btnBackClick} title={title} />
            
            <PostDetailContainer>
                <BoardListWapper>
                    {communityPosts.map((data, index) => (
                        <BoardGameList_Board
                            key={index}
                            imageUrl={data.imageUrl}
                            content={data.title}
                            nickname={data.nickname}
                            createdAt={data.createdAt}
                            commentCount={data.commentCount}
                            onClikedItem={() => onClickedBoardItem(data.postNum)}
                        />
                    ))}
                </BoardListWapper>

                <FloatingButtonWapper onClick={onClickedFloatingButton}>
                    <img src='/images/review/ic_fab1.svg'/>
                </FloatingButtonWapper>
            </PostDetailContainer>
        </CommunityBoardContainer>
    );
};

export default CommunityBoard;
