import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import BogleText from 'components/common/BogleText';
import BogleButton from 'components/common/BogleButton';
import { DateState } from 'pages/review/Review';
import BottomSheet from './BottomSheet';

const BottomSheetTitleWrapper = styled.div`
  width: 100%;
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1.5rem 0 0.5rem;
  justify-content: center;
  color: #32373e;
`;

const BottomSheetSubTitleWapper = styled.div`
  width: 100%;
  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #777e88;
  margin-bottom: 0.5rem;
`;

const BottomSheetListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 12.25rem;
  margin: 0.5rem 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Picker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  z-index: 0;
`;

const PickerItem = styled.div`
  scroll-snap-align: center;
  margin: 6px 0;
  padding: 0 22px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;

  font-family: 'Pretendard JP';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  text-align: center;

  color: #32373E;
`;

const ScrollableListGradientSelection = styled.div`
  position: absolute;
  width: 95%;
  height: 2rem;
  top: calc(50% - 1rem);
  background: #f4e7fd;
  border-radius: 8px;
  z-index: 0;
  pointer-events: none;
`;

const ScrollableListGradientTop = styled.div`
  position: absolute;
  width: 100%;
  height: 4.625rem;
  top: 0;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
  pointer-events: none;
`;

const ScrollableListGradientBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 4.625rem;
  bottom: 0;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(180deg);
  z-index: 2;
  pointer-events: none;
`;

const ITEM_HEIGHT = 40;
const PADDING_ITEMS_COUNT = 2;

const years = ['2000년'];
const months = ['전체', '1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

interface SortBottomSheetProps {
  isBottomSheetOpen: boolean;
  setIsBottomSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  date: DateState;
  setDate: React.Dispatch<React.SetStateAction<DateState>>;
}

const DatePickerBottomSheet: React.FC<SortBottomSheetProps> = ({ isBottomSheetOpen, setIsBottomSheetOpen, date, setDate }) => {
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);

  const yearRef = useRef<HTMLDivElement>(null);
  const monthRef = useRef<HTMLDivElement>(null);

  for (let year = 2000; year <= 2024; year++) {
    if (!years.includes(`${year}년`)) {
        years.push(`${year}년`);
    }
}

  const handleScroll = (ref: React.RefObject<HTMLDivElement>, items: string[], setSelected: React.Dispatch<React.SetStateAction<number>>) => {
    if (ref.current) {
      const { scrollTop, clientHeight } = ref.current;

      const centerIndex = Math.round((scrollTop + clientHeight / 2 - ITEM_HEIGHT / 2) / ITEM_HEIGHT);
      const adjustedIndex = centerIndex - PADDING_ITEMS_COUNT;
      const clampedIndex = Math.max(0, Math.min(adjustedIndex, items.length - 1));

      setSelected(clampedIndex);
    }
  };

  useEffect(() => {
    setSelectedYear(date.year - Number(years[0].replace('년', '')));
    setSelectedMonth(Number(date.month));
  }, [date]);
  
  useEffect(() => {
    const yearScrollHandler = () => handleScroll(yearRef, years, setSelectedYear);
    const monthScrollHandler = () => handleScroll(monthRef, months, setSelectedMonth);

    const yearRefCurrent = yearRef.current;
    const monthRefCurrent = monthRef.current;

    if (yearRefCurrent) {
      yearRef.current.scrollTop = ITEM_HEIGHT * selectedYear;
      yearRefCurrent.addEventListener('scroll', yearScrollHandler);
    }

    if (monthRefCurrent) {
      monthRef.current.scrollTop = ITEM_HEIGHT * selectedMonth;
      monthRefCurrent.addEventListener('scroll', monthScrollHandler);
    }

    return () => {
      if (yearRefCurrent) {
        yearRefCurrent.removeEventListener('scroll', yearScrollHandler);
      }

      if (monthRefCurrent) {
        monthRefCurrent.removeEventListener('scroll', monthScrollHandler);
      }
    };
  }, [isBottomSheetOpen]);

  const onClickedSetDate = () => {
    setIsBottomSheetOpen(false);

    const year = Number(years[0].replace('년', '')) + selectedYear;
    
    const month = selectedMonth;

    setDate({year: year, month: month});
  };

  const renderPaddingItems = (count: number) => {
    return Array.from({ length: count }, (_, i) => (
      <PickerItem key={`padding-${i}`}>
        &nbsp;
      </PickerItem>
    ));
  };

  return (
    <BottomSheet isBottomSheetOpen={isBottomSheetOpen} setIsBottomSheetOpen={setIsBottomSheetOpen}>
      <BottomSheetTitleWrapper>원하시는 날짜를 선택해 주세요</BottomSheetTitleWrapper>
      <BottomSheetSubTitleWapper>태어난 해를 기준으로 골라주세요</BottomSheetSubTitleWapper>
      <BottomSheetListWrapper>
        <ScrollableListGradientSelection />
        <ScrollableListGradientTop />
        <ScrollableListGradientBottom />
        <PickerWrapper>
          <Picker ref={yearRef}>
            {renderPaddingItems(PADDING_ITEMS_COUNT)}
            {years.map((year, index) => (
              <PickerItem key={2024 + index}>
                {year}
              </PickerItem>
            ))}
            {renderPaddingItems(PADDING_ITEMS_COUNT)}
          </Picker>
          <Picker ref={monthRef}>
            {renderPaddingItems(PADDING_ITEMS_COUNT)}
            {months.map((month, index) => (
              <PickerItem key={index}>
                {month}
              </PickerItem>
            ))}
            {renderPaddingItems(PADDING_ITEMS_COUNT)}
          </Picker>
        </PickerWrapper>
      </BottomSheetListWrapper>

      <BogleButton
        width="90%"
        height="3.5rem"
        $background="#A57BDB"
        radius="1rem"
        style={{ position: 'relative', left: '5%', justifyContent: 'center', margin: '2rem 0rem 1.563rem' }}
        onClick={onClickedSetDate}
      >
        <BogleText fontSize="1.063rem">확인</BogleText>
      </BogleButton>
    </BottomSheet>
  );
};

export default DatePickerBottomSheet;
