import React from 'react';

import styled from 'styled-components';

const StyledBogleButton = styled.div<BogleButtonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ radius }) => radius};
  background-color: ${({ $background: background }) => background};
  display: flex;
  position: relative;
  align-items: center;
  
  transition: transform 0.2s ease;

  &:active {
    transform: scale(0.98);
  }

  ${({ $applyAnimation }) => 
    $applyAnimation
      ? `
        &:active {
          background-color: #F0F0F0;
        }
        border-radius: 8px;
      `
      : null
  }
`;

const StyledChecvronRight = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
`;

interface BogleButtonProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;

  width?: string;
  height?: string;
  radius?: string;
  $background?: string;

  $ischevronright?: boolean;

  $applyAnimation?: boolean;
  onClick?: () => void;
}

const BogleButton: React.FC<BogleButtonProps> = ({children, ...props}) => {

  const btnClicked = () => {
    props.onClick && props.onClick();
  };

  return (
    <StyledBogleButton onClick={btnClicked} {...props}>
      {children}
      
      {props.$ischevronright &&
        <StyledChecvronRight>
          <img src='/images/chevron-right.svg' />
        </StyledChecvronRight>}

    </StyledBogleButton>
  );
};

export default BogleButton;
