import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';
import BoardGameList_UserReview from 'components/boardGameList/BoardGameList.UserReview';
import { ReivewData } from 'services/BogleUserApi';

const MyPageContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: calc(100% - 6.375rem);
    position: relative;
    padding-bottom: 6.375rem;
`;

const BottomMainTab = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 24px 0 24px;
    gap: 24px;
`;

const BottomTab = styled.div<{$isFoucs: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 11px 13px;
    width: 50%;
    height: 40px;
`

const BottomTabText = styled.div<{$isFoucs: boolean}>`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: ${({$isFoucs}) => ($isFoucs ? '#8059BC' : '#A9B1BC')};
    transition: color 0.1s ease-in-out;
`

const BottomLineWapper = styled.div`
    display: flex;
    padding: 0 24px;
    position: relative;
`;

const BottomFocusLine = styled.div<{$isFoucs: number}>`
    width: calc(50% - 36px);
    height: 0px;
    border-bottom: 3px solid #8059BC;
    position: absolute;
    transition: transform 0.3s ease-in-out;

    transform: ${({ $isFoucs }) => $isFoucs === 0 ? 'translateX(0%)' : 'translateX(calc(100% + 24px))'};
`;

const BottomTabLine = styled.div`
    width: 100%;
    height: 3px;
    border-bottom: 1px solid #D5D9DD;
`;

const BottomWritingCount = styled.div`
    padding: 40px 24px 40px 24px;
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #32373E;
`;

const BottomWritingList = styled.div`
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    gap: 40px;
`;

const reviews: ReivewData[] = [
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름1',
        rating: '4.0',
        review: '유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안',
        date: '2024.3.3',
        tags: ['전략', '파티'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름2',
        rating: '4.5',
        review: '유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름3',
        rating: '4.1',
        review: '유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안유저의 보드게임평 한줄요약해주기이이이이이이이이이이라머ㅓㅇ리ㅏㅓㅇㄴ리ㅏㅁㄴ어림안',
        date: '2024.3.3',
        tags: ['전략'],
    },
];

const MyPage: React.FC = () => {
    const [tabFocus, setTabFocus] = useState(0);

    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    return (
        <MyPageContainer>
            <AppBar1 backAction={btnBackClick} title='마이 페이지'/>

            <BottomMainTab>
                <BottomTab $isFoucs={tabFocus == 0} onClick={() => setTabFocus(0)}>
                    <BottomTabText $isFoucs={tabFocus == 0}>작성한 글</BottomTabText>
                </BottomTab>
                
                <BottomTab $isFoucs={tabFocus == 1} onClick={() => setTabFocus(1)}>
                    <BottomTabText $isFoucs={tabFocus == 1}>댓글 단 글</BottomTabText>
                </BottomTab>
            </BottomMainTab>

            <BottomLineWapper>
                <BottomTabLine />
                <BottomFocusLine $isFoucs={tabFocus}/>
            </BottomLineWapper>

            <BottomWritingCount>{reviews.length}개의 글이 있어요</BottomWritingCount>

            <BottomWritingList>
                {reviews.map((review, index) => (
                    <BoardGameList_UserReview
                    key={index}
                    reviewData={review}
                    />
                ))}
            </BottomWritingList>
        </MyPageContainer>
    );
};

export default MyPage;
