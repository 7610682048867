import DialogTwoButton from 'components/dialog/Dialog.TwoButton';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

const CreatePostContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
`;

const PostHeaderWapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-top: 47px;
`;

const PostHeaderCancel = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    left: 0;
    width: 64px;
`;

const PostHeaderText = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    // text
    font-family: Pretendard JP;
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
`;

const PostHeaderOk = styled.div`
    display: flex;
    position: absolute;
    right: 15px;
    padding: 9px 12px;
    background: #A57BDB;
    border-radius: 12px;

    //text
    font-family: Pretendard JP;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
`;

const TitleInput = styled.input`
    display: flex;
    height: 20px;
    margin: 28px 24px 0px 24px;
    border: none;
    
    // text
    font-family: Pretendard JP;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;

    &::placeholder {
        font-family: "Pretendard JP";
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #ccc;
    }
`;

const ContentInput = styled.textarea`
    display: flex;
    height: 457px;
    margin: 0px 24px;
    border: none;
    resize: none;
    overflow-wrap: break-word;

    // text
    font-family: Pretendard JP;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;

    &::placeholder {
        font-family: "Pretendard JP";
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #ccc;
    }
`;


const ContourLine = styled.div`
    display: flex;
    background: #D5D9DD;
    margin: 24px;
    height: 1px;
`;

const BottomTextField = styled.div`
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0px;
    height: 90px;

    border-top: 0.5px solid #D5D9DD;
`;

const BottomImgWapper = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    padding: 24px;
`;

const CreatePost: React.FC = () => {
    const [textPopup, settextPopup] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const navigate = useNavigate();

    const onClickedCancel = () => {
        navigate(-1);
    };

    const onClickedOk = () => {
        if(!title.trim() || !content.trim()) {
            settextPopup(true);
        }
        else {
            // todo..
            // 게시글 업데이트 추가 예정..

            settextPopup(false);

            navigate(-1);
        }

    };

    const onLClickedLogOutRight = () => {
        settextPopup(false);
    };

    return (
        <CreatePostContainer>
            <PostHeaderWapper>
                <PostHeaderCancel onClick={onClickedCancel}>
                    <img src='\images\community\x-01.svg' />
                </PostHeaderCancel>

                <PostHeaderText>글쓰기</PostHeaderText>

                <PostHeaderOk onClick={onClickedOk}>
                    완료
                </PostHeaderOk>
            </PostHeaderWapper>

            <TitleInput
                placeholder='제목을 입력하세요.'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />

            <ContourLine />

            <ContentInput
                placeholder='내용을 입력하세요.'
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />

            <BottomTextField>
                <BottomImgWapper>
                    <img src='\images\community\image-03.svg' />
                </BottomImgWapper>
            </BottomTextField>

            {textPopup &&
                <DialogTwoButton
                    title='제목과 본문 내용을 입력해 주세요.'
                    description='입력된 내용이 없습니다.'
                    rightButtonText='확인'
                    show={textPopup}
                    onRightClick={onLClickedLogOutRight}
                />
            }

        </CreatePostContainer>
    );
};

export default CreatePost;
