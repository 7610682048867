import React from 'react';

import styled from 'styled-components';

import { Header2 } from 'components/common/Header';
import BogleNavigation from 'components/common/BogleNavigation';
import BogleButton from 'components/common/BogleButton';
import { useNavigate } from 'react-router-dom';
import ListComponent from 'components/community/ListComponent';

const CommunityContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: calc(100% - 6.375rem);
    position: relative;
    padding-bottom: 6.375rem;
`;

const ContourLine = styled.div`
    display: flex;
    background: #ECECEC;
    margin: 24px;
    height: 1px;
`;

const UserProfileWapper = styled.div`
    display: flex;
    align-items: center;
    padding: 30px 20px 38px 20px;
    gap: 16px;
`;

const UserProfileImg = styled.img`
    display: flex;
    width: 64px;
`;

const UserProfileButtonWapper = styled.div`
    display: flex;
`;

const UserProfileNameWapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    gap: 4px;
`;

const UserProfileNameText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
    color: #32373E;
`;

const UserProfileIntroductionText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: justify;
    color: #777E88;
`;

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 24px;
`;

const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px 24px 20px;
`;

const filterItems = [
    { imageUrl: '/images/community/growing-heart.svg', title: '찜한 글' },
    { imageUrl: '/images/community/sparkles.svg', title: 'BEST' },
    { imageUrl: '/images/community/love-you-gesture.svg', title: '전체 글' },
];
  
const categoryItems = [
    { imageUrl: '/images/community/folder.svg', title: '자유게시판', path: './board/free' },
    { imageUrl: '/images/community/folder.svg', title: '게임후기', path: './board/reviews' },
    { imageUrl: '/images/community/folder.svg', title: '자료실', path: './board/resources' },
    { imageUrl: '/images/community/folder.svg', title: '모임', path: './board/meetups' },
    { imageUrl: '/images/community/folder.svg', title: '중고거래', path: './board/trade' },
    { imageUrl: '/images/community/folder.svg', title: '유저소식', path: './board/news' },
    { imageUrl: '/images/community/folder.svg', title: 'Q&A', path: './board/qna' },
];

const Community: React.FC = () => {

    const navigate = useNavigate();

    const onClickedUserProfile = () => {
        navigate("../my-page");
    };

    return (
        <CommunityContainer>
            <Header2 />

            <UserProfileWapper>
                <UserProfileImg src='/images/profile/user-profile.png'/>
                
                <UserProfileNameWapper>
                    <UserProfileButtonWapper>
                        <BogleButton
                            $ischevronright={true}
                            $applyAnimation={true}
                            style={{padding: '0px 28px 0 0px'}}
                            onClick={onClickedUserProfile}
                        >
                            <UserProfileNameText>유저 닉네임</UserProfileNameText>
                        </BogleButton>
                    </UserProfileButtonWapper>
                    
                    <UserProfileIntroductionText>작성한 글 8 ㅣ 댓글 단 글 123</UserProfileIntroductionText>
                </UserProfileNameWapper>
            </UserProfileWapper>

            <FilterWrapper>
                {filterItems.map((item, index) => (
                    <ListComponent
                        key={index}
                        imageUrl={item.imageUrl}
                        title={item.title}
                    />
                ))}
            </FilterWrapper>

            <ContourLine />

            <CategoryWrapper>
                {categoryItems.map((item, index) => (
                    <ListComponent
                        key={index}
                        imageUrl={item.imageUrl}
                        title={item.title}
                        onClick={() => navigate(item.path, {state: {title: item.title}})}
                    />
                ))}
            </CategoryWrapper>

            <BogleNavigation />
        </CommunityContainer>
    );
};

export default Community;
