import React from "react";
import { useNavigate } from "react-router-dom";

import styled from 'styled-components';

import AppBar2 from "components/appBar/AppBar2";
import SearchListItem from "components/common/SearchList";
import { ReivewData } from "services/BogleUserApi";

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.75rem;
    padding: 1.25rem;
`;

const Search: React.FC = () => {
    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    const reviews: ReivewData[] = [
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름1',
            rating: '4.0',
            review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
            date: '2024.3.3',
            tags: ['파티', '경매'],
        },
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름2',
            rating: '4.5',
            review: '후기 내용2',
            date: '2024.3.3',
            tags: ['파티'],
        },
        {
            imageUrl: '/images/SearchListImg.png',
            name: '보드게임 이름3',
            rating: '4.1',
            review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
            date: '2024.3.3',
            tags: ['파티', '경매'],
        },
    ];

    const onClickedBoardgameList = (review: ReivewData) => {
        navigate('/game/info', { state: review });
    };

    return (
    <>
        <AppBar2 backAction={btnBackClick} placeholder='게임명을 검색해주세요'/>
        <ListWrapper>
            {reviews.map((review, index) => (
            <SearchListItem
                key={index}
                boardGameData={review}
                onClikedItem={() => onClickedBoardgameList(review)}
            />
        ))}
        </ListWrapper>
    </>
    );
}

export default Search;
