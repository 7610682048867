import styled from 'styled-components';

export const ClickEventBackground = styled.div`
  &:active {
    transition: transform 0.2s ease;

    background-color: #F0F0F0;
    border-radius: 8px;
  }
`;

export const ClickEventScale = styled.div`
  &:active {
    transform: scale(0.98);
    transition: transform 0.2s ease;
  }
`;

export const ClickEventAll = styled.div`
  &:active {
    transform: scale(0.98);
    transition: transform 0.2s ease;
    
    background-color: #F0F0F0;
    border-radius: 8px;
  }
`;
