import React from 'react';
import styled from 'styled-components';

import Tag from 'components/common/BogleTag';
import { ReivewData } from 'services/BogleUserApi';
import { ClickEventBackground } from 'components/common/ClickEvent';

const ItemWrapper = styled(ClickEventBackground)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.75rem;
  width: 100%;
  max-width: 21.375rem;
  height: 4rem;
`;

const Image = styled.div<{ $imageUrl: string }>`
  min-width: 3.75rem;
  min-height: 3.75rem;
  background: url(${props => props.$imageUrl}) no-repeat center center;
  background-size: cover;
  border-radius: 0.5rem;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  gap: 0.125rem;
  max-width: 16.875rem;
  height: 4rem;
`;

const NameRatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.75rem;
  max-width: 16.875rem;
  height: 1.375rem;
`;

const GameName = styled.div`
  width: auto;
  height: 1.375rem;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #1F2326;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.25rem;
  width: 2.4375rem;
  height: 1.375rem;
`;

const Rating = styled.div`
  width: 1.4375rem;
  height: 1.375rem;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #A57BDB;
`;

const Review = styled.div`
  max-width: 16.875rem;
  font-family: 'Pretendard JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777E88;
  max-height: 2.75rem;
`;


const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 0.375rem;
  width: 7.875rem;
  height: 1rem;
`;

interface BoardGameList1Props {
  reviewData: ReivewData;
  onClikedItem?: (review: ReivewData) => void;
}

const BoardGameList_Horizontal: React.FC<BoardGameList1Props> = ({ reviewData, onClikedItem }) => {
  return (
    <ItemWrapper onClick={() => onClikedItem && onClikedItem(reviewData)}>
      <Image $imageUrl={reviewData.imageUrl} />
      <InfoWrapper>
        <NameRatingWrapper>
          <GameName>{reviewData.name}</GameName>
          <RatingWrapper>
            <img src='/images/fi-sr-star.png'/>
            <Rating>{reviewData.rating}</Rating>
          </RatingWrapper>
        </NameRatingWrapper>
        <Review>{reviewData.review}</Review>
        {reviewData.tags && reviewData.tags.length > 0 && (
        <TagsWrapper>
          {reviewData.tags.map((tag, index) => (
            <Tag key={index} tagText={tag} />
          ))}
        </TagsWrapper>
        )}
      </InfoWrapper>
    </ItemWrapper>
  );
};

export default BoardGameList_Horizontal;
