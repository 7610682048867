import AppBar1 from 'components/appBar/AppBar1';
import BoardGameList_InTitleNum from 'components/boardGameList/BoardGameList.InTitleNum';
import SortBottomSheet from 'components/BottomSheets/SortBottomSheet';
import BogleNavigation from 'components/common/BogleNavigation';
import BogleText from 'components/common/BogleText';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReivewData } from 'services/BogleUserApi';
import styled from 'styled-components';

const GameCategoryContainer = styled.div`
`;

const GameListTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 1.5rem;
    gap: 0.5rem;
`;

const GameListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    gap: 1.5rem;
`;

const SORT_CRITERIA = {
    LATEST: '최신 등록 순',
    RATING: '별점 높은 순',
    ALPHABETICAL: '가나다 순'
};

const reviews: ReivewData[] = [
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름1',
        rating: '4.0',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름2',
        rating: '4.5',
        review: '후기 내용2',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름3',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름4',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름5',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/BoardGameListImg.png',
        name: '보드게임 이름6',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
      },
  ];

const GameCategory = () => {
    const [sortBottomSheetOpen, setSortBottomSheetOpen] = useState(false);
    const [sort, setSort] = useState(SORT_CRITERIA.LATEST);

    const title = useLocation().state as string;

    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    const onClickedReivewListTop = () => {
        setSortBottomSheetOpen(true);
        setSortBottomSheetOpen(true);
    };

    const onClickedGameList = (review: ReivewData) => {
        navigate('/game/info', { state: review });
    };

    return (
        <GameCategoryContainer>
            <AppBar1 backAction={btnBackClick} title={title}/>

            <GameListTopWrapper onClick={onClickedReivewListTop}>
                <img src='/images/arrow-down-contained-01.svg'/>
                <BogleText color="#32373E">{sort}</BogleText>
            </GameListTopWrapper>

            <GameListWrapper>
                {reviews.map((review, index) => (
                    <BoardGameList_InTitleNum
                    key={index}
                    playTime={213}
                    reviewData={review}
                    onClikedItem={() => {onClickedGameList(review)}}
                    />
                ))}
            </GameListWrapper>

            <BogleNavigation />

            <SortBottomSheet 
                isBottomSheetOpen={sortBottomSheetOpen}
                setIsBottomSheetOpen={setSortBottomSheetOpen}
                sort={sort} 
                setSort={setSort}  
            />

        </GameCategoryContainer>

    );
};

export default GameCategory;
