import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Community from './Community';
import MyPage from './MyPage';
import CommunityBoard from './CommunityBoard';
import CreatePost from './CreatePost';
import PostDetail from './PostDetail';

const CommunityRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Community />} />
            <Route path="/my-page" element={<MyPage />} />
            <Route path="/post" element={<CreatePost />} />
            <Route path="/board/:boardType" element={<CommunityBoard />} />
            <Route path="/board/:boardType/:postNumber" element={<PostDetail />} />
        </Routes>
    );
};

export default CommunityRoutes;
