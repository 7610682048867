export interface CommunityPost {
    postNum: number;      // 게시글 번호
    imageUrl: string;     // 게시글 이미지 URL
    title: string;      // 게시글 내용
    nickname: string;     // 작성자 닉네임
    createdAt: string;    // 게시글 작성 시간
    commentCount: string; // 댓글 수
}

export const communityPosts: CommunityPost[] = [
    {
        postNum: 1,
        imageUrl: '/images/BoardGameListImg.png',
        title: '전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
    {
        postNum: 2,
        imageUrl: '/images/BoardGameListImg.png',
        title: '기후 변화로 인한 자연 재해와 그 영향',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
    {
        postNum: 3,
        imageUrl: '/images/BoardGameListImg.png',
        title: '미래 기술의 발전에 따른 인류의 삶',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
    {
        postNum: 4,
        imageUrl: '/images/BoardGameListImg.png',
        title: '건강한 삶을 위한 운동과 식이요법',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
    {
        postNum: 5,
        imageUrl: '/images/BoardGameListImg.png',
        title: '여름철 피부 관리 방법',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
    {
        postNum: 6,
        imageUrl: '/images/BoardGameListImg.png',
        title: '조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀',
        nickname: '유저 닉네임',
        createdAt: '작성 시간',
        commentCount: '댓글 수',
    },
];

export interface Comment {
    commentId: number;    // 댓글 ID
    nickname: string;     // 댓글 작성자
    content: string;      // 댓글 내용
    createdAt: string;    // 댓글 작성 시간
    depth: number;        // 댓글 깊이 (댓글 0, 대댓글은 1로 설정)
    parentCommentId?: number; // 대댓글의 경우 부모 댓글 ID
}

export interface PostDetail {
    postNum: number;      // 게시글 번호
    imageUrl: string;     // 게시글 이미지 URL
    title: string;        // 게시글 제목
    content: string;      // 게시글 내용
    nickname: string;     // 게시글 작성자 닉네임
    createdAt: string;    // 게시글 작성 시간
    commentCount: string; // 댓글 수
    viewCount: number;    // 게시글 조회수
    comments: Comment[];  // 댓글 리스트
}

export const postDetailData: PostDetail[] = [
    {
        postNum: 1,           // 게시글 번호
        imageUrl: '/images/BoardGameListImg.png', // 게시글 이미지 URL
        title: '전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사', // 게시글 제목
        content: '전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사전국 찜통더위 피해 속출.. 사망 14명 가축 30만마리 폐사', // 게시글 내용
        nickname: '유저 닉네임', // 게시글 작성자 닉네임
        createdAt: '2024-11-09 12:34:56', // 게시글 작성 시간
        commentCount: '3',    // 댓글 수
        viewCount: 123,       // 게시글 조회수
        comments: [
            {
                commentId: 1,    // 댓글 ID
                nickname: '유저1', // 댓글 작성자
                content: '정말 무섭네요. 이번 여름에 어떻게 대처할 수 있을까요?', // 댓글 내용
                createdAt: '2024-11-09 13:00:00', // 댓글 작성 시간
                depth: 0,         // 첫 번째 댓글
            },
            {
                commentId: 2,    // 댓글 ID
                nickname: '유저2', // 댓글 작성자
                content: '좋은 정보 감사합니다.', // 댓글 내용
                createdAt: '2024-11-09 14:00:00', // 댓글 작성 시간
                depth: 0,         // 두 번째 댓글
            },
            {
                commentId: 3,    // 댓글 ID
                nickname: '유저3', // 댓글 작성자
                content: '대처 방법에 대해서 알고 싶어요. 댓글로 답해주세요!', // 댓글 내용
                createdAt: '2024-11-09 15:00:00', // 댓글 작성 시간
                depth: 0,         // 세 번째 댓글
            },
        ],
    },
    {
        postNum: 2,
        imageUrl: '/images/BoardGameListImg.png',
        title: '기후 변화로 인한 자연 재해와 그 영향', // 게시글 제목
        content: '기후 변화로 인해 자연 재해가 심각해지고 있습니다. 이를 방지하기 위한 방법은 무엇일까요?', // 게시글 내용
        nickname: '유저2',
        createdAt: '2024-11-09 13:10:00',
        commentCount: '2',
        viewCount: 97,
        comments: [
            {
                commentId: 4,
                nickname: '유저4',
                content: '기후 변화가 이렇게 심각한 상황이 될 줄 몰랐네요.',
                createdAt: '2024-11-09 13:20:00',
                depth: 0,
            },
            {
                commentId: 5,
                nickname: '유저5',
                content: '기후 변화에 대한 해결책을 찾는 것이 중요하네요.',
                createdAt: '2024-11-09 14:25:00',
                depth: 0,
            },
        ],
    },
    {
        postNum: 3,
        imageUrl: '/images/BoardGameListImg.png',
        title: '미래 기술의 발전에 따른 인류의 삶', // 게시글 제목
        content: '미래 기술이 우리의 삶에 어떤 영향을 미칠지에 대해 알아보겠습니다.', // 게시글 내용
        nickname: '유저3',
        createdAt: '2024-11-09 14:00:00',
        commentCount: '1',
        viewCount: 85,
        comments: [
            {
                commentId: 6,
                nickname: '유저6',
                content: '미래 기술이 우리의 삶에 미치는 영향을 더 연구해야 할 때입니다.',
                createdAt: '2024-11-09 15:30:00',
                depth: 0,
            },
        ],
    },
    {
        postNum: 4,
        imageUrl: '/images/BoardGameListImg.png',
        title: '건강한 삶을 위한 운동과 식이요법', // 게시글 제목
        content: '건강한 삶을 위한 운동과 식이요법에 대해 자세히 알아봅니다.', // 게시글 내용
        nickname: '유저4',
        createdAt: '2024-11-09 16:00:00',
        commentCount: '0',
        viewCount: 72,
        comments: [],
    },
    {
        postNum: 5,
        imageUrl: '/images/BoardGameListImg.png',
        title: '여름철 피부 관리 방법', // 게시글 제목
        content: '여름철 피부를 어떻게 관리할지에 대한 유용한 팁을 알려드립니다.', // 게시글 내용
        nickname: '유저5',
        createdAt: '2024-11-09 17:00:00',
        commentCount: '4',
        viewCount: 110,
        comments: [
            {
                commentId: 7,
                nickname: '유저7',
                content: '여름에는 자외선 차단이 정말 중요하죠!',
                createdAt: '2024-11-09 17:30:00',
                depth: 0,
            },
            {
                commentId: 8,
                nickname: '유저8',
                content: '피부가 민감해서 더 신경 써야겠네요.',
                createdAt: '2024-11-09 18:00:00',
                depth: 0,
            },
            {
                commentId: 9,
                nickname: '유저7',
                content: '저는 SPF50을 사용하고 있어요.',
                createdAt: '2024-11-09 19:00:00',
                depth: 1,
                parentCommentId: 7,
            },
            {
                commentId: 10,
                nickname: '유저9',
                content: '자외선 차단제를 자주 덧발라야 효과가 있다고 들었어요.',
                createdAt: '2024-11-09 20:00:00',
                depth: 0,
            },
        ],
    },
    {
        postNum: 6,
        imageUrl: '/images/BoardGameListImg.png',
        title: '조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀 조금 긴 타이틀', // 게시글 제목
        content: '이번 신제품 출시와 그 기능에 대해 알아보겠습니다.', // 게시글 내용
        nickname: '유저6',
        createdAt: '2024-11-09 18:00:00',
        commentCount: '10',
        viewCount: 150,
        comments: [
            {
                commentId: 11,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 12,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 13,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 14,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 15,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 16,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 17,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 18,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
            {
                commentId: 19,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },            
            {
                commentId: 20,
                nickname: '유저10',
                content: '이번 신제품은 정말 기대됩니다!',
                createdAt: '2024-11-09 19:30:00',
                depth: 0,
            },
        ],
    },
];