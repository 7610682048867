import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Search from '../search/Search';
import Game from './Game';
import GameCategory from './GameCategory';
import Write from 'pages/write/Write';
import GameInformation from '../gameInformation/GameInformation';

const GameRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Game />} />
      <Route path="search" element={<Search />} />
      <Route path="category/*" element={<GameCategory />} />
      <Route path="info" element={<GameInformation />} />
      <Route path="write" element={<Write />} />
    </Routes>
  );
};

export default GameRoutes;
