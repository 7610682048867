import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppBar1 from "components/appBar/AppBar1";
import BogleButton from "components/common/BogleButton";
import BogleText from "components/common/BogleText";
import { ReivewData } from "services/BogleUserApi";
import BoardGameList_UserReview from "components/boardGameList/BoardGameList.UserReview";

const GameInformationWapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

// top
const GameInfoImg = styled.img`
    width: 100%;
    height: 28%;
`;

const AppBarWapper = styled.div`
    position: absolute;
    top: 0;
`;

const GameInfoWapperTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 100%;
    height: 20px;
`;

const InfoRating = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 6px;

    width: 44px;
    height: 20px;
`;

const InfoLine = styled.img`
    width: 0px;
    height: 14px;
    border: 1px solid #777E88;
`;

const InfoTopText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: #777E88;
`;

const InfoLevelText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: #D55816;
`;

const GameInfoWapperBottom = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 32px 0px;
    gap: 5px;

    width: 100%;
    height: 20px;
`;

const InfoBottomWapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100px;
`;

const InfoBottomText = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #32373E;
`;

const ContourLine = styled.div`
    disflay: flex;
    background: #F6F8F9;
    height: 6px;
`;

// bottom
const BottomMainTab = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 24px 0 24px;
    gap: 24px;
`;

const BottomTab = styled.div<{$isFoucs: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 11px 13px;
    width: 50%;
    height: 40px;
`

const BottomTabText = styled.div<{$isFoucs: boolean}>`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    color: ${({$isFoucs}) => ($isFoucs ? '#8059BC' : '#A9B1BC')};
    transition: color 0.1s ease-in-out;
`

const BottomLineWapper = styled.div`
    display: flex;
    padding: 0 24px 0 24px;
    position: relative;
`;

const BottomFocusLine = styled.div<{$isFoucs: number}>`
    width: calc(50% - 36px);
    height: 0px;
    border-bottom: 3px solid #8059BC;
    position: absolute;
    transition: transform 0.3s ease-in-out;

    transform: ${({ $isFoucs }) => $isFoucs === 0 ? 'translateX(0%)' : 'translateX(calc(100% + 24px))'};
`;

const BottomTabLine = styled.div`
    width: 100%;
    height: 3px;
    border-bottom: 1px solid #D5D9DD;
`;

const BottomWritingCount = styled.div`
    padding: 40px 24px 40px 24px;
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #32373E;
`;

const BottomWritingList = styled.div`
    display: flex;
    padding: 0px 24px 0px 24px;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 130px;
`;

const reviews: ReivewData[] = [
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름1',
        rating: '4.0',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략', '파티'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름2',
        rating: '4.5',
        review: '후기 내용2',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름3',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름3',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
    {
        imageUrl: '/images/checker.png',
        name: '보드게임 이름3',
        rating: '4.1',
        review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
        date: '2024.3.3',
        tags: ['전략'],
    },
];

const GameInformation: React.FC = () => {
    const [review] = useState("");
    const [tabFocus, setTabFocus] = useState(0);

    const reviewData = useLocation().state as ReivewData;
    const navigate = useNavigate();

    const btnBackClick = () => {
        navigate(-1);
    };

    const onClickedWriteReview = () => {
        reviewData.review = review;

        navigate('/game/write', { state: reviewData });
    };

    return (
        <GameInformationWapper>
            {/* top */}

            <GameInfoImg src='/images/review/game-image.png' />

            <AppBarWapper onClick={btnBackClick}>
                <AppBar1 />
            </AppBarWapper>

            <BogleText color='#2A2C32' fontSize='1.063rem' style={{ padding: '22px', display: 'flex' }}>{reviewData.name}</BogleText>

            <GameInfoWapperTop>
                <InfoRating>
                    <img src='/images/fi-sr-star.png'/>
                    <InfoTopText>{reviewData.rating}</InfoTopText>
                </InfoRating>

                <InfoLine src='/images/game/vector-line.svg'/>

                <InfoTopText>{reviewData.tags}</InfoTopText>

                <InfoLine src='/images/game/vector-line.svg'/>

                <InfoLevelText>HARD</InfoLevelText>
            </GameInfoWapperTop>

            <GameInfoWapperBottom>
                <InfoBottomWapper>
                    <img src='/images/game/emoj-icons-smiling-face.svg'/>
                    <InfoBottomText>2~4명</InfoBottomText>
                </InfoBottomWapper>

                <InfoBottomWapper>
                    <img src='/images/game/emoj-icons-t-rex.svg'/>
                    <InfoBottomText>30분+</InfoBottomText>
                </InfoBottomWapper>
                
                <InfoBottomWapper>
                    <img src='/images/game/emoj-icons-partying-face.svg'/>
                    <InfoBottomText>50살 이상</InfoBottomText>
                </InfoBottomWapper>

            </GameInfoWapperBottom>

            <ContourLine />
            {/* bottom */}

            <BottomMainTab>
                <BottomTab $isFoucs={tabFocus == 0} onClick={() => setTabFocus(0)}>
                    <BottomTabText $isFoucs={tabFocus == 0}>리뷰</BottomTabText>
                </BottomTab>
                
                <BottomTab $isFoucs={tabFocus == 1} onClick={() => setTabFocus(1)}>
                    <BottomTabText $isFoucs={tabFocus == 1}>정보</BottomTabText>
                </BottomTab>
            </BottomMainTab>

            <BottomLineWapper>
                <BottomTabLine />
                <BottomFocusLine $isFoucs={tabFocus}/>
            </BottomLineWapper>

            <BottomWritingCount>{reviews.length}개의 글이 있어요</BottomWritingCount>

            <BottomWritingList>
                {reviews.map((review, index) => (
                    <BoardGameList_UserReview
                    key={index}
                    reviewData={review}
                    />
                ))}
            </BottomWritingList>

            <BogleButton
                width="90%"
                height="3.5rem"
                $background="#A57BDB"
                radius="1rem"
                style={{ position: 'fixed', left: '5%', bottom: '0', justifyContent: 'center', margin: '2rem 0rem 1.563rem' }}
                onClick={onClickedWriteReview}
            >
                <BogleText fontSize="1.063rem">후기 작성</BogleText>
            </BogleButton>
        </GameInformationWapper>
    );
};

export default GameInformation;
