import { ClickEventAll } from 'components/common/ClickEvent';
import React from 'react';
import styled from 'styled-components';

const ListWrapper = styled(ClickEventAll)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const ListItem = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
    gap: 12px;
`;

const ImageWrapper = styled.img`
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
`;

const Title = styled.span`
    font-family: Pretendard JP;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
`;

interface ListItemProps {
    imageUrl: string;
    title: string;
    onClick?: () => void;
}

const ListComponent: React.FC<ListItemProps> = ({ imageUrl, title, onClick }) => {
  return (
    <ListWrapper onClick={onClick}>
        <ListItem>
            <ImageWrapper src={imageUrl} />
            <Title>{title}</Title>
        </ListItem>
    </ListWrapper>
  );
};

export default ListComponent;
