import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import BogleNavigation from 'components/common/BogleNavigation';
import SearchBar from 'components/common/SearchBar';
import BogleText from 'components/common/BogleText';
import BogleButton from 'components/common/BogleButton';
import BoardGameList_Horizontal from 'components/boardGameList/BaordGameList.Horizontal';
import BoardGameList_Vertical from 'components/boardGameList/BoardGameList.Vertical';
import { Header1 } from 'components/common/Header';
import { ReivewData } from 'services/BogleUserApi';
import BoardGameBottomSheet from 'components/BottomSheets/BoardGameBottomSheet';

const HomeContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: calc(100% - 6.375rem);
  position: relative;
  padding-bottom: 6.375rem;
`;

const SearchBarContainer = styled.div`
  display: flex;
  position: relative;
  top: -1px;
  width: 100%;
  padding: 1.5rem 0rem 1.5rem 0rem;
  justify-content: center;
  background: #a57bdb;
`;

const ReviewListTitle = styled.div`
  display: flex;
  height: 3.5rem;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0rem 1.5rem 0rem 1.5rem;
`;

const ReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 2rem;
`;

const GameListTitle = styled.div`
  display: flex;
  height: 3.5rem;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0rem 1.5rem 0rem 1.5rem;
`;

const GameListType = styled.div`
  width: 8.375rem;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
`;

const GameListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 8%;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
`;

const Home: React.FC = () => {
  const [gamelistType, setGamelistType] = useState<'일간' | '주간'>('일간');
  const [boardGametBottomSheetOpen, setBoardGameBottomSheetOpen] = useState(false);
  const [reviewData, setReviewData]= useState<ReivewData | null>(null);

  const reviews: ReivewData[] = [
    {
      imageUrl: '/images/BoardGameListImg.png',
      name: '보드게임 이름1',
      rating: '4.0',
      review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
      date: '2024.3.3',
      tags: ['전략'],
    },
    {
      imageUrl: '/images/BoardGameListImg.png',
      name: '보드게임 이름2',
      rating: '4.5',
      review: '후기 내용2',
      date: '2024.3.3',
      tags: ['전략'],
    },
    {
      imageUrl: '/images/BoardGameListImg.png',
      name: '보드게임 이름3',
      rating: '2.0',
      review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
      date: '2024.3.3',
      tags: ['전략'],
    },
    {
      imageUrl: '/images/BoardGameListImg.png',
      name: '보드게임 이름4',
      rating: '4.0',
      review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
      date: '2024.3.3',
      tags: ['전략'],
    },
    {
      imageUrl: '/images/BoardGameListImg.png',
      name: '보드게임 이름5',
      rating: '2.0',
      review: '보드게임 후기 한줄로 작성합니다 이렇게하면 두줄로 보여집니다',
      date: '2024.3.3',
      tags: ['전략'],
    },
  ];

  const navigate = useNavigate();

  const clickedSearchBar = () => {
    navigate('search');
  };

  const clickedReview = () => {
    navigate('search');
  };

  const clcikedBoardGameReview = (review: ReivewData) => {
    setBoardGameBottomSheetOpen(true);
    setReviewData(review);
  };

  const clcikedBoardGame = (review: ReivewData) => {
    navigate('/game/info', { state: review });
  };

  return (
    <HomeContainer>
      <Header1/>
      <SearchBarContainer>
        <SearchBar
          width="90%"
          placeholder="보드게임을 검색해 주세요"
          onSearchClick={clickedSearchBar}
        ></SearchBar>
      </SearchBarContainer>

      <ReviewListTitle>
        <BogleButton
          width="100%"
          height="56px"
          $background="#FFFFFF"
          $ischevronright={true}
          $applyAnimation={true}
          onClick={clickedReview}>
            <BogleText color="#32373E" fontSize="1.063rem">신규 리뷰✍️</BogleText>
        </BogleButton>
      </ReviewListTitle>
      <ReviewListWrapper>
        {reviews.slice(0, 3).map((review, index) => (
          <BoardGameList_Horizontal
            key={index}
            reviewData={review}
            onClikedItem={() => clcikedBoardGameReview(review)}
          />
        ))}
      </ReviewListWrapper>

      <GameListTitle>
        <BogleText color="#32373E" fontSize="1.063rem">
          요즘 뜨는 보드게임 ✨
        </BogleText>
        <GameListType>
          <div onClick={() => setGamelistType('일간')}>
            <BogleText
              width="2.688rem"
              fontSize="0.813rem"
              style={{ display: 'flex' }}
              color={gamelistType === '일간' ? '#32373E' : '#777E88'}
            >
              일간
            </BogleText>
          </div>
          <img
            style={{ width: '0', height: '13px', border: '1px solid #32373E' }}
            src="images/vector1.svg"
          />
          <div onClick={() => setGamelistType('주간')}>
            <BogleText
              width="2.688rem"
              fontSize="0.813rem"
              style={{ display: 'flex' }}
              color={gamelistType === '주간' ? '#32373E' : '#777E88'}
            >
              주간
            </BogleText>
          </div>
        </GameListType>
      </GameListTitle>
      <GameListWrapper>
        {reviews.slice(0, 5).map((review, index) => (
          <BoardGameList_Vertical
            key={index}
            reviewData={review}
            recordCount={123}
            badgeLabel={index + 1 + '등'}
            onClikedItem={() => clcikedBoardGame(review)}
          />
        ))}
      </GameListWrapper>
      <BogleNavigation />

      <BoardGameBottomSheet 
        isBottomSheetOpen={boardGametBottomSheetOpen}
        setIsBottomSheetOpen={setBoardGameBottomSheetOpen}
        reviewData={reviewData} 
      />

    </HomeContainer>
  );
};

export default Home;
