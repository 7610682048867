import React from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.div`
    display: flex;
    position: relative;
    padding: 16px 0px;
    gap: 0.75rem;
`;

const ImageWapper = styled.div`
    display: flex;
    position: relative;
    right: 0;
    justify-content: center;
    align-items: center;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const NameRatingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;

    // text
    font-family: Pretendard JP;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #32373E;
`;

const GameData = styled.div`
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #777E88;
`;

interface BoardGameList1Props {
    imageUrl: string;
    content: string;
    nickname: string,
    createdAt: string
    commentCount: string,
    onClikedItem?: () => void;
}

const BoardGameList_Board: React.FC<BoardGameList1Props> = ({ imageUrl, content, nickname, createdAt, commentCount, onClikedItem }) => {
    return (
    <ItemWrapper onClick={() => onClikedItem && onClikedItem()}>
        <InfoWrapper>
            <NameRatingWrapper>
                {content}
            </NameRatingWrapper>

            <GameData>{nickname} ㆍ {createdAt} ㆍ {commentCount}</GameData>
        </InfoWrapper>

        <ImageWapper>
            <img src={imageUrl} />
        </ImageWapper>

    </ItemWrapper>
    );
};

export default BoardGameList_Board;
