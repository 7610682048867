import { gql } from '@apollo/client';
import { News, Qna } from 'commons/types/types';

export interface FindQnaResponse {
  findQNA: Qna[];
}

export const FIND_QNA = gql`
  query {
    findQNA {
      id
      question
      answer
    }
  }
`;

export interface FindNewsListResponse {
  findNewsList: News[];
}

export const FIND_NEWS_LIST = gql`
  query {
    findNewsList {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;

export interface FindNewsResponse {
  findNews: {
    id: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
  }
}

export interface FindNewsVariables {
  id: number;
}

export const FIND_NEWS = gql`
  query FindNews($id: Int!) {
    findNews(data: { id: $id }) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;