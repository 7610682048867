import React from "react";

import styled from "styled-components";

const GameCategoryWapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 0px;
    gap: 1rem;
    align-items: center;
`;

const GameCategoryImgWapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    min-width: 48px;
    min-height: 48px;
    background: rgba(55, 55, 88, 0.03);
    border-radius: 100000px;
`;

const GameCategoryText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 48px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    justify-content: center;

    font-family: 'Pretendard JP';
    font-style: normal;
`;

const GameCategoryTitle = styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #1F2326;
`;

const GameCategorySubTitle = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #777E88;
`;

interface gameCategoryDataInfo {
    imageUrl: string;
    title: string;
    subTitle: string;
}

const gameCategoryData: gameCategoryDataInfo[] = [
    {
        imageUrl: '/images/game/emoj-icons-sparkling-heart.svg',
        title: '커플에서 하기 좋은 게임',
        subTitle: '보드게임 후기 한줄',
    },
    {
        imageUrl: '/images/game/emoj-icons-t-rex.svg',
        title: '4명 이상일때',
        subTitle: '보드게임 후기 한줄',
    },
    {
        imageUrl: '/images/game/emoj-icons-star.svg',
        title: '초보자 추천',
        subTitle: '보드게임 후기 한줄',
    },
    {
        imageUrl: '/images/game/emoj-icons-water-wave.svg',
        title: '에디터 추천 게임',
        subTitle: '보드게임 후기 한줄',
    },
    {
        imageUrl: '/images/game/emoj-icons-waving-hand.svg',
        title: '플레이타임 2시간 이상 게임',
        subTitle: '보드게임 후기 한줄',
    },

];

const GameCategory: React.FC = () => {
    return(
        <>
            {gameCategoryData.map((data, index) => (
                <GameCategoryWapper key={index}>
                    
                    <GameCategoryImgWapper>
                        <img src={data.imageUrl} />
                    </GameCategoryImgWapper>

                    <GameCategoryText>
                        <GameCategoryTitle>{data.title}</GameCategoryTitle>
                        <GameCategorySubTitle>{data.subTitle}</GameCategorySubTitle>
                    </GameCategoryText>

                </GameCategoryWapper>
            ))}
        </>
    );
};


export default GameCategory;