import React from 'react';

import styled from 'styled-components';

const InputContainer = styled.div`
    display: flex;
    position: fixed;
    bottom: 8px;
    width: 100%;
    height: 48px;
`;

const InputWapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
`;

const InputField = styled.input`
    display: flex;
    width: 100%;
    height: 32px;
    max-height: 96px;
    margin: 8px 12px;
    padding: 8px 16px;
    border-radius: 12px;
    border: 0.3px;
    background: #F6F8F9;
`;

const SendButton = styled.button`
    display: flex;
    position: absolute;
    padding: 8px 10px;
    right: 24px;
    border-radius: 10px;
    border: none;

    background: #A57BDB;

    // text
    font-family: Pretendard JP;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
`;

const KeyboardInput = () => {
    return (
    <InputContainer>
        <InputWapper>
            <InputField placeholder="댓글을 입력하세요..." />
            <SendButton>전송</SendButton>
        </InputWapper>
    </InputContainer>
    );
};

export default KeyboardInput;